<template>
   <div class="col-12 px-0 mb-1" ref="produto">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-md-block">
                  <img :src="produto.banner == null ? '' : produto.banner" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-sm text-center text-sm-start">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ produto.nome }}</h1>
                        <p class="font-10 mb-0 mb-md-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                           <span class="ms-3">
                              <i class="far fa-tags font-9 me-1"></i>
                              <span>{{ produto.familia == null || String(produto.familia).trim().length == 0 ? 'Sem família' : produto.familia }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col d-none d-md-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Código(s):</strong>
                           <span> {{ produto.codigos.filter(e => e.ean != null).length == 0 ? 'Sem código' : String(produto.codigos.filter(e => e.ean != null).map(e => e.ean)).replace(/,/g, ", ") }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="wpx-115 text-center mx-auto mx-sm-0 mt-2 mt-sm-0" v-if="showEdit">
                  <label><i class="far fa-dollar-sign color-theme font-9 me-1"></i> Preço</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="produto.preco" />
               </div>
               <div class="wpx-115 text-center mx-auto mx-sm-0 mt-2 mt-sm-0 font-15" v-else>
                  <span class="d-block lh-1"><small>R$ </small>{{ produto.preco == null ? '0,00' : parseFloat(produto.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
               </div>
               <div class="w-max-content text-center font-16 mx-auto mx-sm-0 mt-2 mt-sm-0">
                  <div class="btn-icone text-red" v-if="showEdit">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
                  <div class="btn-icone color-theme" v-else>
                     <span @click="select"><i class="far fa-check"></i><small>Selecionar</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Produto1',
   props: ['produto', 'index', 'showEdit'],
   watch: {
      'produto.preco': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
            this.produto.preco = valor
         })
      }
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      select : function () {
         if (!this.produto.preco) this.produto.preco = 0

         this.produto.quantidade = 1
         this.$emit('select', this.produto)
      },
      remover : function () {
         this.$emit('remover', this.index)
      }
   },
   mounted() {
      this.produto.preco = this.produto.preco == null ? '0.00' : parseFloat(this.produto.preco).toFixed(2)
   }
}

</script>