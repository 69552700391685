<template>
   <div class="modal fade" id="modalProdutoPreco" tabindex="-1" aria-labelledby="modalProdutoPrecoLabel" aria-hidden="true">
      <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
         <div class="modal-content h-100">
            <div class="modal-header">
               <h5 class="modal-title" id="modalProdutoPrecoLabel">Selecionar produto</h5>
               <button type="button" class="btn-close" data-bs-toggle="modal" :data-bs-target="modalAnterior" aria-label="Close"></button>
            </div>
            <div class="modal-body">
               <div class="row">
                  <pesquisaPreco ref="pesquisaPreco" :resumido="true" @setRetorno="setRetornoPesquisaProduto($event)" />
                     
                  <div class="col-12 px-0" v-if="pesquisaProduto.resultado.length > 0">
                     <div class="card mb-0">
                        <div class="card-body p-12">
                           <div class="row">
                              <div class="col-12 mb-2 align-self-center">
                                 <div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisaProduto.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisaProduto.resultado.length }} resultado(s)</div>
                              </div>

                              <produto v-for="(produto, index) in pesquisaProduto.resultado" :key="index" :produto="produto" :index="index" :showEdit="false" @select="setProduto($event)" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="modalAnterior">Voltar</button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import produto from '@/components/modelos/Produto1.vue'
import pesquisaPreco from '@/components/modelos/PesquisaPreco.vue'

export default {
   name: 'ModalProdutoPreco',
   props: ['modalAnterior'],
   data: function () {
		return {
			pesquisaProduto: {'categoria': null, 'subcategoria': null, 'nome': '', 'marca': null, 'resultado': []}
		}
	},
   components: {
		produto, pesquisaPreco
	},
   methods: {
      setRetornoPesquisaProduto : function (retorno) {
			this.pesquisaProduto = this.$options.data.call(this).pesquisaProduto;
			setTimeout(() => this.pesquisaProduto = retorno, 50);
		},
      setProduto : function (value) {
         this.$emit("addProduto", value)
         $('#modalProdutoPreco').modal('hide')
         $(this.modalAnterior).modal('show')
      }
   },
   mounted() {
		this.$store.dispatch('getCategorias')
		this.$store.dispatch('getMarcas')
	}
}

</script>